<template>
  <v-card>
    <v-container>
      <v-card-title class="py-0">
        <span class="primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text class="py-0">
        <v-form
          v-model="formularioValido"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveVendedor"
        >
          <v-card elevation="3" class="my-3 p-2 py-0">
            <h6 class="p-1 py-2">Datos vendedor</h6>

            <v-row>
              <v-col cols="7" md="7" sm="12" class="py-0">
                <v-text-field
                  v-model.trim="venAfiNombre"
                  label="Apellido y nombre"
                  dense
                  outlined
                  :rules="
                    rules.required.concat([
                      rules.maxLength(venAfiNombre, 30),
                      rules.requiredTrim(venAfiNombre)
                    ])
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="5" md="5" sm="12" class="py-0">
                <v-text-field
                  v-model.trim="cuit"
                  label="CUIT"
                  dense
                  v-mask="'##-########-#'"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" sm="12" class="py-0">
                <v-autocomplete
                  v-model="tipoIVASelected"
                  :items="tiposIVA"
                  item-text="value"
                  item-value="id"
                  label="Condición de IVA"
                  :rules="rules.required"
                  outlined
                  clearable
                  dense
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="7" md="7" sm="12" class="py-0">
                <v-text-field
                  v-model.trim="direccion"
                  label="Domicilio"
                  dense
                  :rules="[rules.maxLength(direccion, 100)]"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="5" md="5" sm="12" class="py-0">
                <v-text-field
                  v-model.trim="telefono"
                  label="Teléfono"
                  dense
                  :rules="[rules.maxLength(telefono, 15)]"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-textarea
                  rows="1"
                  v-model.trim="observaciones"
                  label="Observaciones"
                  dense
                  :rules="[rules.maxLength(observaciones, 100)]"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card>

          <!-- USUARIOS -->
          <v-card class="my-4 p-2 py-0" elevation="3" v-if="canUser">
            <v-row>
              <v-col cols="12" class="py-0">
                <h6 class="p-1 py-2">
                  Usuarios
                </h6>
                <v-autocomplete
                  class="mb-0 mt-0 pt-0"
                  outlined
                  clearable
                  dense
                  multiple
                  v-model="usuariosSelect"
                  label="Usuarios"
                  item-text="nombre"
                  item-value="nombre"
                  autocomplete="on"
                  :items="usuariosItems"
                  return-array
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="usuariosToggle()">
                      <v-list-item-action>
                        <v-icon
                          :color="usuariosSelect.length > 0 ? 'primary' : ''"
                        >
                          {{ multiselectIconVendedores }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title> Todos </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.nombre }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text caption">
                      (+{{ usuariosSelect.length - 1 }} otros)
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card>
        </v-form>
      </v-card-text>
      <v-card-actions class="py-0 pr-4">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          :loading="loadingBtn"
          type="submit"
          :disabled="!formularioValido || loadingBtn"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import enums from "@/utils/enums/index.js";

export default {
  name: "EditVendedor",
  props: {
    editVendedor: {
      type: Object,
      required: false
    },
    canUser: {
      type: Boolean,
      required: false
    }
  },
  directives: { mask },
  data() {
    return {
      formularioValido: false,
      mSCloseBoxIcon: enums.icons.CLOSE_BOX,
      checkBoxBlankOutlineIcon: enums.icons.CHECKBOX_BLANK_OUTLINE,
      formEditTitle: "Editar vendedor",
      rules: rules,
      tipoIVASelected: null,
      venAfiNombre: null,
      cuit: null,
      tiposIVA: [],
      direccion: null,
      telefono: null,
      observaciones: null,
      loadingBtn: false,
      usuariosSelect: [],
      usuariosItems: []
    };
  },
  async created() {
    await this.setSelects();
    if (this.editVendedor != null) {
      this.setVendedor();
    } else {
      this.newVendedor();
    }
  },
  computed: {
    multiselectIconVendedores() {
      if (this.selectAllVendedores) return this.mSCloseBoxIcon;
      return this.checkBoxBlankOutlineIcon;
    },
    selectAllVendedores() {
      return (
        this.usuariosSelect &&
        this.usuariosSelect.length === this.usuariosItems.length
      );
    }
  },
  methods: {
    ...mapActions({
      getUsuarios: "configuracion/getUsuariosCajeros",
      getTiposIvaCobradores: "configuracion/getTiposIvaCobradores",
      postVendedores: "configAfiliaciones/postVendedores",
      setAlert: "user/setAlert"
    }),
    async setSelects() {
      const usuarios = await this.getUsuarios();
      this.usuariosItems = usuarios;
      const tiposIVAs = await this.getTiposIvaCobradores();
      this.tiposIVA = tiposIVAs;
    },
    setVendedor() {
      const verUsers = this.editVendedor.usuarios
      this.venAfiNombre = this.editVendedor.venAfiNombre;
      this.cuit = this.editVendedor.cuit;
      this.tipoIVASelected = this.editVendedor.tipoIva.id;
      this.direccion = this.editVendedor.direccion;
      this.telefono = this.editVendedor.telefono;
      this.observaciones = this.editVendedor.observaciones;
      this.usuariosSelect = this.editVendedor.usuarios;
      
    },
    newVendedor() {
      this.formEditTitle = "Nuevo vendedor";
    },
    async saveVendedor() {
      this.loadingBtn = true;
      const data = {
        venAfiId: this.editVendedor != null ? this.editVendedor.venAfiId : null,
        venAfiNombre: this.venAfiNombre,
        cuit: this.cuit,
        tipoIva: this.tiposIVA.find(x => x.id === this.tipoIVASelected),
        direccion: this.direccion,
        telefono: this.telefono,
        usuarios: this.usuariosSelect,
        observaciones: this.observaciones
      };

      try {
        const res = await this.postVendedores(data);
        if (res.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito" });
          this.closeModal();
        }
        this.loadingBtn = false;
      } catch {
        this.loadingBtn = false;
      }
    },

    usuariosToggle() {
      this.$nextTick(() => {
        if (this.selectAllVendedores) this.usuariosSelect = [];
        else this.usuariosSelect = this.usuariosItems.map(x => x.nombre);
      });
    },

    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style scoped></style>
