<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
        <v-data-table
          :headers="headers"
          :items="vendedores"
          :search="search"
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:[`item.venAfiNombre`]="{ item }">
            <td>{{ item.venAfiNombre.toUpperCase() }}</td>
          </template>

          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="9">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col cols="3" align="end" v-if="canCreate">
                  <v-btn
                    color="primary"
                    class="to-right"
                    @click="openModalEditVendedor()"
                  >
                    Nuevo
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModalEditVendedor(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar vendedor</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="deleteVendedor(item.venAfiId)"
                  :loading="isLoading"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar vendedor</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-row>
          <v-col cols="12" md="12">
            <v-tooltip top max-width="35%">
              <template v-slot:activator="{ attrs }">
                <v-btn
                  color="primary"
                  class="to-right"
                  v-bind="attrs"
                  outlined
                  dense
                  @click="exportExcelVendedores"
                >
                  Exportar detalle
                </v-btn>
              </template>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-6 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="modalEditVendedor"
      v-model="modalEditVendedor"
      max-width="50%"
      @keydown.esc="closeAndReload()"
      persistent
    >
      <EditVendedor
        :editVendedor="editVendedor"
        :canUser="canUser"
        @closeAndReload="closeAndReload"
      />
    </v-dialog>
    <DeleteDialog
      :isLoading="isLoading"
      :titleProp="titleDelete"
      :maxWidth="'35%'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";
import EditVendedor from "@/components/modules/afiliaciones/afiliados/configuracion/EditVendedor.vue";
import { mask } from "vue-the-mask";
import helpersExcel from "@/utils/helpers/importExportExcel";

export default {
  name: "Vendedores",
  components: {
    PageHeader,
    DeleteDialog,
    EditVendedor,
    GoBackBtn,
    Ayuda
  },
  directives: { mask },
  data: () => ({
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    checkIcon: enums.icons.CHECK_OUTLINE,
    showExpand: false,
    showHelp: false,
    showIcon: true,
    isLoading: false,
    optionCode: enums.webSiteOptions.VENDEDORES,
    showDeleteModal: false,
    idToDelete: null,
    searchIcon: enums.icons.SEARCH,
    search: "",
    title: enums.titles.VENDEDORES,
    routeToGo: "SolicitudesDeAfiliacion",
    titleDelete: "¿Desea eliminar el vendedor?",
    vendedores: [],
    rules: rules,
    expanded: [],
    headers: [
      {
        text: "Apellido y nombre",
        align: "start",
        value: "venAfiNombre",
        sortable: false
      },
      {
        text: "CUIT",
        align: "center",
        value: "cuit",
        sortable: false
      },
      {
        text: "Condición de IVA",
        align: "start",
        value: "tipoIva.value",
        sortable: false
      },
      {
        text: "Domicilio",
        align: "start",
        value: "direccion",
        sortable: false
      },
      {
        text: "Teléfono",
        align: "start",
        value: "telefono",
        sortable: false
      },
      {
        text: "Observaciones",
        align: "start",
        value: "observaciones",
        sortable: false
      },

      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    canUser: false,
    modalEditVendedor: false,
    editVendedor: null
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadVendedores();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getVendedores: "configAfiliaciones/getVendedores",
      deleteVendedores: "configAfiliaciones/deleteVendedores",
      getUsuarios: "configuracion/getUsuariosCajeros",
      getTiposIvaCobradores: "configuracion/getTiposIvaCobradores",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVO_VENDEDOR:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_VENDEDOR:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.ELIMINAR_VENDEDOR:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.USUARIOS:
            this.canUser = true;
            break;
          default:
            break;
        }
      });
    },
    async loadVendedores() {
      const gvendedores = await this.getVendedores();
      this.vendedores = gvendedores;
    },
    deleteVendedor(id) {
      this.idToDelete = id;
      this.showDeleteModal = true;
    },
    async confirmDelete() {
      this.isLoading = true;
      try {
        const response = await this.deleteVendedores(this.idToDelete);
        if (response.status === 200) {
          this.setAlert({ type: "success", message: "Eliminado con éxito." });
          this.showDeleteModal = false;
          this.loadVendedores();
          this.isLoading = false;
        }
      } catch {
        this.showDeleteModal = false;
        this.isLoading = false;
      }
    },
    openModalEditVendedor(item) {
      this.editVendedor = item;
      this.modalEditVendedor = !this.modalEditVendedor;
    },
    closeAndReload() {
      this.modalEditVendedor = false;
      this.loadVendedores();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    exportExcelVendedores() {
      let result = [];
      this.vendedores?.forEach(x =>
        result.push({
          ["Apellido y nombre"]: x.venAfiNombre,
          ["CUIT"]: x.cuit,
          ["Condición de IVA"]: x.tipoIva.value,
          ["Domicilio"]: x.direccion,
          ["Teléfono"]: x.telefono,
          ["Observaciones"]: x.observaciones
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado lista"
      };
      helpersExcel.excelExport(formato, "Lista vendedores");
    }
  }
};
</script>

<style lang="scss" scoped></style>
